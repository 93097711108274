import { logger } from '@/utils/logger'

export default {
    methods: {
        toFormattedUSDStripTrailingZeroCents(number: number) {
            let stringNum = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)

            stringNum = stringNum.replace(/\.00$/, '')

            return stringNum
        },
        toThousands(val: number) {
            return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toFormattedUSD(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        toFormattedUSDNoCents(number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
            }).format(number)
        },
        formatMoney(value: string) {
            return '$' + parseInt(value).toLocaleString()
        },
        formatApr(value: number) {
            return `${parseFloat(String(value * 100)).toFixed(2)}%`
        },
        // use lodash?
        capitalize: (value: string) => {
            if (!value) return ''

            const ret = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
            logger.info(`value: ${value} camcelCase: ${ret}`)
            return ret
        },
    },
}
