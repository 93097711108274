// keep in line with SweepstakeStatus in aven_backend/src/entity/sweepstakes.ts
import { HomeOwnershipStatus, AvenAdvisorLeadAccountInfoPayload, AvenAdvisorNumberOfBedrooms } from 'aven_types'

// Keep in sync with aven_backend/src/avenAdvisor/controller/avenAdvisorAuthentication.types.ts
export interface PrefillInformation {
    firstName: string
    lastName: string
    dateOfBirth: Date
    addressData: PrefillAddressData
}

export interface PrefillAddressData {
    addressCity: string
    addressState: string
    addressPostalCode: string
    addressStreet: string
}

// Keep in sync with AvenAdvisorCreateAccountPayload in aven_backend/src/controller/avenAdvisorController.ts
export interface AvenAdvisorAddressPayload {
    addressStreet: string
    secondaryAddressDesignator?: string | null
    secondaryAddressUnit?: string | null
    addressCity: string
    addressState: string
    addressPostalCode: string
    homeOwnershipStatus: HomeOwnershipStatus
    numberOfBedrooms?: AvenAdvisorNumberOfBedrooms
}

// currently do not support changing ownership status, just address
export type AvenAdvisorLeadUpdateAddressField = keyof Omit<AvenAdvisorAddressPayload, 'homeOwnershipStatus'>

// keep in sync with AvenAdvisorLeadUpdatedField in aven_backend/src/entity/entityUpdateAuditLog/avenAdvisorLeadUpdateAuditLog.ts
export type AvenAdvisorLeadUpdatedField = keyof Pick<AvenAdvisorLeadAccountInfoPayload, 'firstName' | 'lastName' | 'email'> & AvenAdvisorLeadUpdateAddressField

// Keep in sync with aven_backend/src/provider/Crimeometer/crimeometerProvider.types.ts
export interface NeighborhoodCrimeData {
    lastUpdatedAt: Date
    radius: number // in miles
    homeAddressLatitude: number
    homeAddressLongitude: number
    crimeReports: CrimeReport[]
}

export interface CrimeReport {
    incidentDate: string // YYYY-MM-DD
    incidentAddress: string
    incidentType: string
    incidentSource: string
    incidentLat: number
    incidentLong: number
}

// keep in sync with aven_backend/src/avenAdvisor/manager/avenAdvisorStreakManager.types.ts
export enum Badge {
    BRONZE = 'BRONZE', // 4 weeks in a row
    SILVER = 'SILVER', // 8 weeks in a row
    GOLD = 'GOLD', // 16+ weeks in a row
}

// keep in sync with aven_backend/src/avenAdvisor/manager/avenAdvisorStreakManager.types.ts
export interface BadgeMetadata {
    currentBadge: Badge | null
    nextBadge: Badge | null
    badgeAchievedAt: Record<Badge, Date> // If the date is in the future then the badge has not been achieved
}

// keep in sync with aven_backend/src/avenAdvisor/manager/avenAdvisorStreakManager.types.ts
export interface StreakResponse {
    numConsecutiveVisitWeekCount: number
    streakStartDate: Date
    lastVisitDate: Date
    badgeMetadata: BadgeMetadata
    totalNumWeeksInStreaks: number
}

export const DEFAULT_LIMIT_ON_CRIME_REPORTS = 20
