import { isRunningInNativeApp, saveAccessTokenOnNative } from '@/nativeWebInteraction/nativeWebInteraction'
import { logger } from '@/utils/logger'

export interface PersistedState {
    sessionAccessJWT: string
    sessionId: string
    jwtTokens: any
    consentToCreditOfferDisclosuresHash: string | null
    consentToExperianBoostDataSharingHash: string | null
    consentToMethodFiTosGitHash: string | null
    eSignConsentGitHash: string | null
}

interface UpdateConsentToDisclosuresHashPayload {
    hash: string | null
}

interface UpdateSessionInfo {
    sessionAccessJWT: string
    sessionId: string
}

export const persistedStore = {
    state: {
        // The field below comes from the React native frontend while we test
        // whether moving the first 2 onboarding screens to native results in
        // higher conversion.
        consentToCreditOfferDisclosuresHash: window.onboardingConsentToCreditOfferDisclosuresHash || null,
        sessionAccessJWT: '',
        sessionId: '',
        jwtTokens: '',
        consentToExperianBoostDataSharingHash: null,
        consentToMethodFiTosGitHash: window.onboardingConsentToMethodFiTosGitHash || null,
        eSignConsentGitHash: null,
    },
    getters: {
        isUserSignedIn(state: PersistedState) {
            return !!state.jwtTokens
        },
    },
    mutations: {
        updateSessionInfo(state: PersistedState, payload: UpdateSessionInfo) {
            state.sessionId = payload.sessionId
            state.sessionAccessJWT = payload.sessionAccessJWT
        },
        updateJwtTokens(state: PersistedState, payload: any) {
            state.jwtTokens = payload
            if (isRunningInNativeApp()) {
                logger.log(`Aven Advisor is running within native app. Trying to save access token on native`)
                try {
                    let accessToken: string | null = null
                    if (typeof payload === 'string') {
                        accessToken = JSON.parse(payload).accessJWT
                    } else if (payload && payload.accessJWT) {
                        accessToken = payload.accessJWT
                    }
                    saveAccessTokenOnNative(accessToken)
                } catch (error) {
                    logger.fatal(`Aven Advisor is running within native app, but couldn't save access token`, error)
                }
            } else {
                logger.log('Aven Advisor is not running within native app, so not trying to save access token on native')
            }
        },
        updateJwtTokensWithoutSavingToNative(state: PersistedState, payload: any) {
            state.jwtTokens = payload
        },
        updateConsentToCreditOfferDisclosuresHash(state: PersistedState, payload: UpdateConsentToDisclosuresHashPayload) {
            state.consentToCreditOfferDisclosuresHash = payload.hash
        },
        updateConsentToExperianBoostDataSharingDisclosuresHash(state: PersistedState, payload: UpdateConsentToDisclosuresHashPayload) {
            state.consentToExperianBoostDataSharingHash = payload.hash
        },
        updateConsentToMethodFiTosGitHash(state: PersistedState, payload: UpdateConsentToDisclosuresHashPayload) {
            state.consentToMethodFiTosGitHash = payload.hash
        },
        updateEsignConsentGitHash(state: PersistedState, payload: UpdateConsentToDisclosuresHashPayload) {
            state.eSignConsentGitHash = payload.hash
        },
    },
}
