import { RouteConfig } from 'vue-router'

export const supportRoutePaths = {
    SUPPORT: '/support',
}

export const supportRoutes: RouteConfig[] = [
    {
        path: supportRoutePaths.SUPPORT,
        component: () => import(/* webpackChunkName: "support-mailto-redirect", webpackPrefetch: true */ '@/pages/support/SupportMailtoRedirect.vue'),
        meta: { public: true },
    },
]
