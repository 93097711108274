import { RouteConfig } from 'vue-router'
import Landing from '@/pages/Landing.vue'
import { appRoutePaths } from '@/routes/appRoutes'

export const sharedRoutePaths = {
    ROOT: '/',
    ROOT_WEB_OG: '/webOrigination',
    ENABLE_NOTIFICATION: `/enableNotifications`,
    ENABLE_NOTIFICATION_PLAID: `/enableNotificationsPlaid`,
    ENABLE_NOTIFICATION_PASSWORD_RESET: `/enableNotificationsPasswordReset`,
    ENABLE_NOTIFICATION_SIGN_IN: `/enableNotificationsSignIn`,
    OTP_VERIFICATION: '/otpVerification',
} as const

export const sharedRoutes: RouteConfig[] = [
    {
        path: sharedRoutePaths.ROOT,
        // Don't lazy load Landing because this seems to cause the landing bundle to load after many of the other
        // bundles, meaning slower initial page load.
        component: Landing,
        meta: { public: true },
    },
    {
        path: sharedRoutePaths.ROOT_WEB_OG,
        component: Landing,
        meta: { public: true },
        props: { isWebOG: true },
    },
    {
        path: sharedRoutePaths.ENABLE_NOTIFICATION,
        component: () => import(/* webpackChunkName: "notification", webpackPrefetch: true */ '@/pages/Notification.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: sharedRoutePaths.ENABLE_NOTIFICATION_PASSWORD_RESET,
        component: () => import(/* webpackChunkName: "notification", webpackPrefetch: true */ '@/pages/Notification.vue'),
        meta: { public: false },
        props: { nextRoutePath: sharedRoutePaths.OTP_VERIFICATION },
    },
    {
        path: sharedRoutePaths.ENABLE_NOTIFICATION_SIGN_IN,
        component: () => import(/* webpackChunkName: "notification", webpackPrefetch: true */ '@/pages/Notification.vue'),
        meta: { public: false },
        props: { nextRoutePath: sharedRoutePaths.OTP_VERIFICATION },
    },
    {
        path: sharedRoutePaths.ENABLE_NOTIFICATION_PLAID,
        component: () => import(/* webpackChunkName: "notification", webpackPrefetch: true */ '@/pages/Notification.vue'),
        meta: { public: false, requiresAuth: true },
        props: { nextRoutePath: appRoutePaths.PLAID_LINK },
    },
    {
        path: sharedRoutePaths.OTP_VERIFICATION,
        component: () => import(/* webpackChunkName: "otp-verification", webpackPrefetch: true */ '@/pages/OtpVerification.vue'),
        meta: { public: false },
    },
]
