<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div class="input form-floating">
                <input
                    type="tel"
                    inputmode="tel"
                    :autocomplete="autocomplete"
                    class="form-control"
                    v-mask="'###-###-####'"
                    :id="name"
                    :class="{ 'is-invalid': errors[0], 'is-valid': valid }"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    :data-testid="dataTestid"
                    v-model="phoneValue"
                    @blur="onBlur($event.target)"
                    @focus="onFieldFocus($event.target)"
                >
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormFieldPhone',
        mixins: [formInputMixin],
        props: {
            validationRules: { type: String, default: 'required|phoneNumberRule' },
            dataTestid: {
                type: String,
                required: false,
            },
            autocomplete: {
                type: String,
                default: 'tel',
            },
            defaultValue: {
                type: String, // Change the type based on your initial value type
                default: '', // Set a default initial value if needed
            },
            focusEvent: {
                type: String,
                required: false,
            },
        },
        methods: {
            onFieldFocus(target) {
                if (this.focusEvent) {
                    this.$logEvent(this.focusEvent)
                }

                this.$emit('focus', target)
            },
        },
        data() {
            return {
                phoneValue: this.defaultValue, // Initialize with the initial value from prop
            }
        },
        watch: {
            phoneValue: function (newValue, oldValue) {
                logger.log(`phoneValue: ${oldValue} -> ${newValue}`)
                const filteredValue = newValue?.replace(/[()-.]/gi, '')
                this.$emit('input', filteredValue)
            },
        },
    }
</script>
