"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuaranteedForeclosureProtectionStatus = void 0;
/**
 * Mutually Exclusive Collectively Exhaustive status for Guaranteed Foreclosure Protection
 *     - Not eligible (they don't fulfill requirements)
 *     - Eligible (they fulfill requirements)
 *       - Opted out
 *       - Opted in
 */
var GuaranteedForeclosureProtectionStatus;
(function (GuaranteedForeclosureProtectionStatus) {
    GuaranteedForeclosureProtectionStatus["notEligible"] = "notEligible";
    GuaranteedForeclosureProtectionStatus["eligible"] = "eligible";
    GuaranteedForeclosureProtectionStatus["optedOut"] = "optedOut";
    GuaranteedForeclosureProtectionStatus["optedIn"] = "optedIn";
})(GuaranteedForeclosureProtectionStatus = exports.GuaranteedForeclosureProtectionStatus || (exports.GuaranteedForeclosureProtectionStatus = {}));
