import { isRunningInNativeApp, saveAccessTokenOnNative } from '@/nativeWebInteraction/nativeWebInteraction'
import { logger } from '@/utils/logger'
import store from '@/store'
import router from '@/routes/router'
import { sharedRoutePaths } from '@/routes/sharedRoutes'
import { logEvent } from '@/utils/http-client'

export enum LogoutReason {
    clickButton = 'clickButton',
    response401 = 'response401',
    blockedAccount = 'blockedAccount',
    deleteAccount = 'deleteAccount',
}
export const logout = (logoutReason: LogoutReason, blockAccount = false, authFailureReason?: string) => {
    logger.log(`Clearing Aven Advisor data upon logout`)
    if (isRunningInNativeApp()) {
        logger.log(`Aven Advisor is running in a native shell, so trying to clear the access token from the native shell as part of logout`)
        saveAccessTokenOnNative(null)
    }
    store.commit('logout')
    const query = blockAccount ? { isAccountBlocked: 'true' } : undefined
    router.replace({ path: sharedRoutePaths.ROOT, query })
    logEvent('event_advisor_logout', { logoutReason, authFailureReason })
}
