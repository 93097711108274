import { startCase } from 'lodash'
import { RecurringTransactionFrequency } from 'aven_types'

export const toFormattedUSD = (number: number, fractionDigits?: number): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: fractionDigits,
        // minimumFractionDigits is required to set maximumFractionDigits < 2
        minimumFractionDigits: fractionDigits,
    }).format(number)
}

export const formatNumber = (number: number, fractionDigits?: number): string => {
    return new Intl.NumberFormat('en-US', {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
    }).format(number)
}

export const toFormattedUSDKMOrB = (number: number, includePlusForPositive = false, includeDecimalPlaces: boolean = true): string => {
    const absNumber = Math.abs(number)
    let formattedNumber: string
    let suffix: string = ''

    if (absNumber >= 1_000_000_000) {
        formattedNumber = formatNumber(absNumber / 1_000_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'B'
    } else if (absNumber >= 1_000_000) {
        formattedNumber = formatNumber(absNumber / 1_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'M'
    } else if (absNumber >= 1000) {
        formattedNumber = formatNumber(absNumber / 1000, includeDecimalPlaces ? 1 : 0)
        suffix = 'K'
    } else {
        formattedNumber = formatNumber(absNumber, 0)
    }

    let sign = ''
    if (number >= 0) {
        sign = includePlusForPositive ? '+' : ''
    } else {
        sign = '-'
    }
    return `${sign}$${formattedNumber}${suffix}`
}

export const toFormattedNumberKMOrB = (number: number, includeDecimalPlaces: boolean = true): string => {
    const absNumber = Math.abs(number)
    let formattedNumber: string
    let suffix: string = ''

    if (absNumber >= 1_000_000_000) {
        formattedNumber = formatNumber(number / 1_000_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'B'
    } else if (absNumber >= 1_000_000) {
        formattedNumber = formatNumber(number / 1_000_000, includeDecimalPlaces ? 2 : 0)
        suffix = 'M'
    } else if (absNumber >= 1000) {
        formattedNumber = formatNumber(number / 1000, includeDecimalPlaces ? 1 : 0)
        suffix = 'K'
    } else {
        formattedNumber = formatNumber(number, 0)
    }

    return `${formattedNumber}${suffix}`
}

export const toFormattedPercent = (number: number, fractionDigits?: number, includePlusForPositive = false): string => {
    return `${includePlusForPositive && number > 0 ? '+' : ''}${new Intl.NumberFormat('en-US', {
        style: 'percent',
        maximumFractionDigits: fractionDigits,
    }).format(number)}`
}

export const formatDateAsMMDDString = (date: Date): string => {
    return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
    })
}

export const formatFrequencyAsString = (frequency: RecurringTransactionFrequency): string => {
    switch (frequency) {
        case RecurringTransactionFrequency.Unknown:
            return ''
        case RecurringTransactionFrequency.Annually:
            return 'Yearly -'
        case RecurringTransactionFrequency.SemiMonthly:
            return 'Semi-Monthly -'
        default:
            return `${startCase(frequency.toLowerCase())} -`
    }
}

export const formatPhoneNumber = (phoneNumberString: string): string | null => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        const intlCode = match[1] ? '+1 ' : ''
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
}

export const formatCrimeReportSourceString = (source: string): string => {
    return source
        .split('_') // Split the string by underscores
        .map(
            (word, index) =>
                index === 0
                    ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize the first word
                    : word.toLowerCase() // Make other words lowercase
        )
        .join(' ') // Join the words with spaces
}
