import { logger } from '@/utils/logger'
import LogRocket from 'logrocket'
import { submitSessionRecordingUrl } from './marketing'

export const initLogRocket = (sessionId: string, userTraits: Record<string, any>) => {
    const rootHostname = process.env.VUE_APP_AVEN_URL
    if (['production', 'uat'].includes(process.env.VUE_APP_NODE_ENV as string) && process.env.VUE_APP_LOGROCKET_ID) {
        logger.info(`initLogRocket with sessionId: ${sessionId}`)
        try {
            LogRocket.init(process.env.VUE_APP_LOGROCKET_ID, {
                rootHostname,
            })
            LogRocket.identify(sessionId, userTraits)
            LogRocket.getSessionURL((sessionURL: string) => {
                submitSessionRecordingUrl(sessionId, sessionURL)
            })
        } catch (error) {
            logger.info(`LogRocket failed to initialize for sessiondId: ${sessionId}`)
        }
    } else {
        submitSessionRecordingUrl(sessionId, 'local dev test logrocket session url')
    }
}
