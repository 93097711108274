import { Logger, inspect } from 'aven_shared/src/utils/logger'
import { appSessionStorage, localStorageKey } from './storage'

const getMetadata = (): Record<string, string> => {
    const metadata: Record<string, string> = {}
    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
    if (sessionId) {
        metadata.sessionId = sessionId
    }
    const sessionRecordingUrl = appSessionStorage.getItem(localStorageKey.sessionRecordingUrl)
    if (sessionRecordingUrl) {
        metadata.sessionRecordingUrl = sessionRecordingUrl
    }

    if (window.nativeAppVersion) {
        metadata.nativeAppVersion = window.nativeAppVersion
    }

    if (window.installId) {
        metadata.nativeAppInstallId = window.installId
    }
    return metadata
}

const logger = new Logger({
    avenProject: 'advisor',
    sentryDSN: process.env.VUE_APP_SENTRY_ID ?? '',
    logProcessorBaseUrl: process.env.VUE_APP_LOGGER_BASE_URL,
    clientSideLogsEnabled: process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes',
    getMetadata,
})

export { logger, inspect }
