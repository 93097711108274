import { RouteConfig } from 'vue-router'

export const passwordResetRoutePaths = {
    SSN_AND_DOB: `/passwordReset/ssnDob`,
    NEW_PASSWORD: `/passwordReset/newPassword`,
}

export const passwordResetRouteNames = {
    SSN_AND_DOB: 'SSN_AND_DOB',
    NEW_PASSWORD: 'NEW_PASSWORD',
}

export const passwordResetRoutes: RouteConfig[] = [
    {
        path: passwordResetRoutePaths.SSN_AND_DOB,
        name: passwordResetRouteNames.SSN_AND_DOB,
        component: () => import(/* webpackChunkName: "password-reset-ssn-dob", webpackPrefetch: true */ '@/pages/passwordreset/PasswordResetSsnDob.vue'),
        meta: { public: false },
    },
    {
        path: passwordResetRoutePaths.NEW_PASSWORD,
        name: passwordResetRouteNames.NEW_PASSWORD,
        props: true,
        component: () => import(/* webpackChunkName: "password-reset-new-password", webpackPrefetch: true */ '@/pages/passwordreset/PasswordResetNewPassword.vue'),
        meta: { public: false },
    },
]
