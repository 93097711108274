import mixins from 'vue-typed-mixins'
import throttleMixin from './throttleMixin'
import { ValidationProvider } from 'vee-validate'

/**
 * base mixin that other form input will inherit below
 */
export const formInputMixin = mixins(throttleMixin).extend({
    mixins: [throttleMixin],
    props: {
        name: { type: String, required: true },
        value: { type: String, default: null },
        validationRules: { type: String, default: null },
        label: { type: String, default: null },
        focusPlaceholder: { type: String, default: null },
        placeholder: { type: String, default: null },
        autocomplete: { type: String, default: null },
        loading: { type: Boolean, default: false },
        inputType: { type: String, default: 'text' },
        disabled: { type: Boolean, default: false },
        inputmode: {
            type: String,
            default: 'text',
        },
        validationMode: {
            type: String,
            default: 'lazy',
            validator: function (value: string) {
                return ['eager', 'aggressive', 'passive', 'lazy'].indexOf(value) !== -1
            },
        },
    },
    data: function () {
        return {
            dynamicPlaceholder: this.placeholder,
        }
    },
    components: {
        'validation-provider': ValidationProvider,
    },
    mounted() {
        // Shows a different placeholder on focus
        if (this.focusPlaceholder) {
            const _this = this
            const dynamicPlaceholder = document.getElementById(this.name)
            if (!dynamicPlaceholder) {
                return
            }
            dynamicPlaceholder.onfocus = function () {
                _this.dynamicPlaceholder = _this.focusPlaceholder
            }
            dynamicPlaceholder.onblur = function () {
                _this.dynamicPlaceholder = _this.placeholder
            }
        }
    },
    methods: {
        onFocus() {
            this.$emit('focus')
        },
        onInput(eventTarget: any) {
            const filteredValue: string = eventTarget.value
            this.throttleLogging(`${eventTarget.name}: ${filteredValue}`)
            this.$emit('input', filteredValue)
        },
        onBlur(eventTarget: any) {
            const logEvent = this.$logEvent as any
            logEvent('event_field_interaction', {
                type: 'blur',
                value: eventTarget.value || '',
                name: eventTarget.name || '',
            })
            this.$emit('blur', eventTarget.value)
        },
        applyError(errorMessage: any) {
            const provider = this.$refs.provider as any
            provider.applyResult({
                errors: [errorMessage], // array of string errors
                valid: false, // boolean state
                failedRules: {}, // should be empty since this is a manual error.
            })
        },
    },
})
