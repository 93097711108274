import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, TimeSeriesScale, LineElement, Filler } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import Annotation from 'chartjs-plugin-annotation'

// a little overkill for just a border on top of the labels
const BorderTopLabelsPlugin = {
    id: 'BorderTopLabelsPlugin',
    beforeDraw: (chart: { ctx: any; data: any; scales: { x: any; y: any } }) => {
        const {
            ctx,
            data,
            scales: { x },
        } = chart
        ctx.save()

        ctx.lineWidth = 1

        for (let i = 0; i < data.labels.length; i++) {
            const textWidth = ctx.measureText(data.labels[i]).width - 6
            ctx.beginPath()
            ctx.fillStyle = '#d8dcdf'
            ctx.strokeStyle = '#d8dcdf'
            ctx.moveTo(x.getPixelForValue(i) - textWidth, x.top + 3)
            ctx.lineTo(x.getPixelForValue(i) + textWidth, x.top + 3)
            ctx.closePath()
            ctx.stroke()
            ctx.restore()
        }
    },
}

export const initChartJs = () => {
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeSeriesScale, PointElement, LineElement, ChartDataLabels, BorderTopLabelsPlugin, Filler)
    ChartJS.register(Annotation)
    ChartJS.defaults.set('plugins.datalabels', {
        display: false,
    })
}
