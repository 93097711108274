import { inspect, logger } from '@/utils/logger'
import { httpClient } from '@/utils/http-client'
import 'url-search-params-polyfill' // Just to be safe
import OpenReplayTracker from '@openreplay/tracker'
import { submitSessionRecordingUrl } from './marketing'
import { InputMode } from '@openreplay/tracker/lib/modules/input'

class OpenReplay {
    public openReplayTracker: OpenReplayTracker | undefined

    public init = async (sessionId: string, userTraits: Record<string, any>) => {
        if (this.openReplayTracker) {
            logger.info(`Tried to initialize openReplay twice in the same session, exiting instead`)
            return
        }

        logger.info(`OpenReplay with sessionId: ${sessionId}`)
        try {
            let sessionRecordingUrl: string | undefined
            if (process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
                this.openReplayTracker = new OpenReplayTracker({
                    projectKey: process.env.VUE_APP_OPENREPLAY_PROJECT_KEY,
                    ingestPoint: 'https://openreplay.aven.com/ingest',
                    captureIFrames: false, // don't capture very sensitive info
                    obscureInputEmails: false,
                    obscureInputNumbers: false,
                    obscureInputDates: false,
                    obscureTextEmails: false,
                    obscureTextNumbers: false,
                    defaultInputMode: InputMode.Plain,
                })

                await this.openReplayTracker.start({
                    userID: sessionId,
                    sessionHash: sessionId, // enables cross domain linking of sessions
                    metadata: userTraits,
                })

                logger.info(`Adding request watcher for openreplay`)
                httpClient.initializeOpenReplayRequestTracker(this.openReplayTracker)

                logger.info(`OpenReplay initialized with the following userTraits: ${JSON.stringify(userTraits)}`)

                logger.info(`Attempting to get openreplay sessionRecordingUrl for sessionID ${sessionId}`)
                sessionRecordingUrl = this.openReplayTracker.getSessionURL()
            } else {
                logger.info(`Skipping openreplay initialization on ${process.env.VUE_APP_NODE_ENV} env`)
                sessionRecordingUrl = 'local dev test openreplay session url'
            }

            if (sessionRecordingUrl) {
                await submitSessionRecordingUrl(sessionId, sessionRecordingUrl)
            } else {
                logger.error(`Could not get session recording url for openreplay`)
            }
        } catch (error) {
            logger.error(`openreplay failed to initialize for sessiondId: ${sessionId}.`, error)
        }
    }

    public trySetMetadata = (metadata: Record<string, any>) => {
        if (!this.openReplayTracker) {
            logger.error(`Tried to set metadata with uninitialized openreplay exiting instead`)
            return
        }
        try {
            logger.log(`Trying to set metadata on openreplay: ${inspect(metadata)}`)
            for (const [trait, value] of Object.entries(metadata)) {
                this.openReplayTracker.setMetadata(trait, value)
            }
        } catch (error) {
            logger.error(`failed to setMetadata on openreplay: ${inspect(metadata)}.`, error)
        }
    }
}

export const openReplay = new OpenReplay()
