/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughtout the application
 * Default, this is just using the LocalStorage
 */
import assert from 'assert'
import { inspect } from '@/utils/logger'

const localStorageKey = {
    sessionId: 'sessionId',
    currentFlow: 'currentFlow',
    locale: 'locale',
    sessionAccessJWT: 'sessionAccessJWT',
    experimentsOverrides: 'experimentsOverrides',
    helocCardInfo: 'helocCardInfo',
    autoCardInfo: 'autoCardInfo',
    sessionRecordingUrl: 'sessionRecordingUrl',
    sessionRecordingInitialized: 'sessionRecordingInitialized',
    // this overrides the timeout value for axios only used for cypress tests atm due to them being http/1.1 https://github.com/cypress-io/cypress/issues/3708
    httpTimeout: 'httpTimeout',
    skippedConnectPlaidInterstitial: 'skippedConnectPlaidInterstitial',
}

export class AppStorage {
    private storage: Storage

    constructor(storage?: Storage) {
        this.storage = storage || window.localStorage

        /** Is storage is supported or not */
        if (!this.isSupported()) {
            throw new Error('Storage is not supported by browser!')
        }
    }

    // window.storage only accepts string
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    setItem(key: string, value: string) {
        assert(typeof key === 'string', `AppStorage.setItem key must be a string. Instead got ${inspect(key)}`)
        assert(typeof value === 'string', `AppStorage.setItem value for ${key} must be a string. Instead got ${inspect(value)}`)
        this.storage.setItem(key, value)
    }

    getItem(key: string) {
        const value = this.storage.getItem(key)
        return value
    }

    removeItem(key: string) {
        this.storage.removeItem(key)
    }

    clear() {
        this.storage.clear()
    }

    /** @param exceptedKeyList string[] The keys we want to keep */
    clearWithException(exceptedKeyList: string[]) {
        const clearKeys: string[] = []
        Object.keys(this.storage).forEach((key) => {
            if (!exceptedKeyList.includes(key)) {
                clearKeys.push(key)
            }
        })
        clearKeys.forEach((key) => this.removeItem(key))
    }

    getAll() {
        return Object.assign({}, this.storage)
    }

    /**
     * @description Check for storage support
     * @returns {boolean} supported
     * */
    isSupported() {
        let supported = true

        if (!this.storage) {
            supported = false
        }

        return supported
    }
}

/**
 * Creating the instance of storage. Default will be localStorage
 * but if you want to create instance for session storage then pass window.sessionStorage as parameter
 */

const appLocalStorage = new AppStorage()
const appSessionStorage = new AppStorage(window.sessionStorage)

export { appLocalStorage, appSessionStorage, localStorageKey }
