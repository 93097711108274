<template>
    <div id="app">
        <div class="onboarding-layout-container d-block text-center">
            <img
                src="@/assets/images/global/aven.svg"
                alt="Aven Logo"
                class="logo d-block mx-auto"
            >
            <div class="onboarding-layout-content container mx-auto">
                <img
                    src="@/assets/images/global/sorryicecream.svg"
                    width="200px"
                    alt="Magnifying Glass with ID"
                >
                <h5 class="font-weight-light">
                    Your network appears to interfere with some of our site features. Please try again on your mobile device.
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    export default Vue.extend({
        name: 'NetworkUnavailable',
    })
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/main.scss';

    .logo {
        height: 28px;
        margin-top: 20px;
    }

    $onboarding-layout-breakpoint: 480px; //map-get($grid-breakpoints, "sm");

    .onboarding-layout-container {
        .onboarding-layout-content {
            padding-top: 24px;
            padding-bottom: 24px;
            max-width: $onboarding-layout-breakpoint;
        }
    }
</style>
