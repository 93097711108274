import { extend, configure } from 'vee-validate'
import startCase from 'lodash/startCase'
import { required, email, min, max, numeric, length, min_value, max_value } from 'vee-validate/dist/rules'
import { i18n } from './i18n'
import { logger } from '@/utils/logger'
import { us_states } from '@/utils/us-states-dictionary'
import { ageIsZeroOrNegative, ageIsBelowMinAge, MINIMUM_AGE_YEARS, correctDateOfBirthFormat } from '@/utils/date'
import format from '@/mixins/format'
import tldEnum from 'tld-enum'
import { last } from 'lodash'

configure({
    bails: false,
    classes: undefined,
    mode: undefined,
    skipOptional: false,
    useConstraintAttrs: false,
    defaultMessage: (field, values) => {
        return i18n.t(`validation.${values._rule_}`, values) as string
    },
})

// Install required rule and message.
extend('required', required)

// Install length rule and message.
extend('length', length)

// Install email rule and message.
extend('email', {
    ...email,
    validate: (value) => {
        const tld = last(value?.split('.'))
        if (!tldEnum.list.includes(tld)) {
            return false
        }

        return email.validate(value, {
            require_tld: true,
            allow_utf8_local_part: false,
            domain_specific_validation: true,
        })
    },
})

// Install min rule and message.
extend('min', min)

// Install min rule and message.
extend('min_value', min_value)
// Install min rule and message.
extend('max_value', max_value)

// Install max rule and message.
extend('max', max)

// Install numeric rule and message.
extend('numeric', numeric)

// Install full name (2 word/space in between minimum) rule
extend('fullname', {
    validate: (value) => {
        if (!/\w+\s\w+/.test(value)) {
            return i18n.t('validation.fullname') as string
        }
        return true
    },
})

extend('signatureValidator', {
    params: ['suggestedSignature'],
    message: (field, params) => {
        return `Signature must match ${params.suggestedSignature}`
    },
    validate: (value, { suggestedSignature }: Record<string, any>) => {
        return value.toLowerCase().trim() === suggestedSignature.toLowerCase().trim()
    },
})

// date validation rule
extend('addressRule', {
    validate: (value) => {
        const usAddress = value.toLowerCase().trimEnd(', usa')
        const addressComponents = usAddress.split(',')
        const addressStreet = addressComponents[0]
        const addressCity = addressComponents[1]
        const stateZip = addressComponents[2].trim().split(' ')
        const addressState = stateZip[0]
        const addressPostalCode = stateZip[1]

        if (!/^\d{5}/.test(addressPostalCode)) {
            logger.info('addressRule: invalid zip code')
            return i18n.t('validation.invalidPostalCode') as string
        }

        if (!us_states[addressState.toUpperCase() as keyof typeof us_states]) {
            logger.info('addressRule: invalid state')
            return i18n.t('validation.invalidState') as string
        }

        if (!/^\d+(\s[a-zA-Z0-9]+)+/.test(addressStreet)) {
            logger.info('addressRule: street regex failed')
            return i18n.t('validation.invalidStreetAddress') as string
        }

        if (!addressCity) {
            logger.info('addressRule: invalid city')
            return i18n.t('validation.invalidCity') as string
        }
        return true
    },
})

extend('secondaryAddressUnitRule', {
    validate: (value) => {
        if (!value) {
            return true
        }
        logger.info(`apt input ${value}`)
        const parts = value.trim().split(/[ ]+/)
        logger.info(`apt input parts: ${parts}`)

        if (parts.length > 2 || parts.length === 0) {
            return i18n.t('validation.malformedSecondaryAddress') as string
        }
        const unitNumber = parts.pop()
        logger.info(`unit number candidate: ${unitNumber}`)
        /*
            \d{1,6} matches a digit (equal to [0-9])
            {1,6} Quantifier — Matches between 1 and 6 times, as many times as possible, giving back as needed (greedy)
            \w{0,1} matches any word character (equal to [a-zA-Z0-9_])
            {0,1} Quantifier — Matches between zero and one times, as many times as possible, giving back as needed (greedy)
            Negative Lookahead (?!.)
            Assert that the Regex below does not match
            . matches any character (except for line terminators)
         */
        if (/\d{1,6}\w?(?!.)/.test(unitNumber) && unitNumber.length < 6) {
            logger.info(`${unitNumber} match`)
            return true
        } else {
            logger.info(`${unitNumber} not match \\d{1,6}\\w?(?!.)`)
            return i18n.t('validation.malformedSecondaryAddress') as string
        }
    },
})

// date validation rule
extend('dateRule', {
    validate: (value) => {
        if (correctDateOfBirthFormat(value)) {
            return true
        } else {
            return i18n.t('validation.invalidDate') as string
        }
    },
})

// date validation rule
extend('phoneNumberRule', {
    validate: (value) => {
        // Replace any extra chars (i.e. () / . / - )
        value = value?.replace(/[-.() ]/gi, '')

        logger.info(`phoneNumberRule validation on: ${value}`)

        if (value.length === 12 && value.startsWith('+1')) {
            return true
        }
        if (value.length === 11 && value.startsWith('1')) {
            return true
        }
        if (value.length === 10 && !(value.startsWith('1') || value.startsWith('+'))) {
            return true
        }

        return i18n.t('customValidationRules.notValidPhoneNumber') as string
    },
})

extend('californiaResidencyRule', {
    validate: (value) => {
        logger.info(`californiaResidencyRule validation on: ${value}`)
        const isCalifornia = value.toLowerCase() === 'california'
        if (isCalifornia) {
            return true
        }
        const indexOfCA = value.indexOf('CA')
        if (indexOfCA < 0) {
            return i18n.t('customValidationRules.CAResidentOnly') as string
        }
        return true
    },
})

extend('ageRule', {
    validate: (value) => {
        if (ageIsZeroOrNegative(value)) {
            return i18n.t('customValidationRules.zeroOrNegativeAge') as string
        } else if (ageIsBelowMinAge(value)) {
            return i18n.t('customValidationRules.underAge', { minAgeYears: MINIMUM_AGE_YEARS }) as string
        }
        return true
    },
})

extend('addressAutoCompleteRule', {
    validate: (value) => {
        logger.info(`addressAutoCompleteRule validation on: ${value}`)
        const addressComponents = value.split(',')
        if (addressComponents.length < 3) {
            return i18n.t('customValidationRules.incompleteAddress') as string
        }
        const addressStreet = startCase(addressComponents[0].toLowerCase())
        const addressCity = startCase(addressComponents[1].toLowerCase())
        const addressState = addressComponents[2].toUpperCase()
        const country = addressComponents[3]

        if (addressStreet && addressCity && addressState && country) {
            return true
        }
        return i18n.t('customValidationRules.incompleteAddress') as string
    },
})

extend('twilioCode', {
    validate: (value) => {
        if (isNaN(value)) {
            return 'Only numbers are allowed'
        } else if (value.length != 6) {
            return 'Code must be exactly 6 digits'
        }
        return true
    },
})

extend('currency', {
    validate: (value, { min, max }: any) => {
        value = value?.replace(/[$,]/gi, '')

        logger.info(`currency validation on: ${value}`)

        value = parseFloat(value)
        const minAmount = min !== undefined ? min : -Infinity
        const maxAmount = max !== undefined ? max : Infinity
        if (isNaN(value)) {
            return i18n.t('customValidationRules.invalidCurrency') as string
        } else if (value < minAmount) {
            return i18n.t('customValidationRules.currencyTooSmall', { minAmount: format.methods.toFormattedUSDStripTrailingZeroCents(minAmount) }) as string
        } else if (value > maxAmount) {
            return i18n.t('customValidationRules.currencyTooLarge', { maxAmount: format.methods.toFormattedUSDStripTrailingZeroCents(maxAmount) }) as string
        }
        return true
    },
    params: ['min', 'max'],
})

extend('ssnLast4', {
    validate: (value) => {
        // Keep only digits for validation
        value = value?.replace(/[^0-9]/gi, '')
        if (value.length !== 4) {
            return i18n.t('customValidationRules.ssnLast4') as string
        }
        return true
    },
})

extend('ssnFull9', {
    validate: (value) => {
        // Keep only digits for validation
        value = value?.replace(/[^0-9]/gi, '')
        if (value.length !== 9) {
            return i18n.t('customValidationRules.ssnFull9') as string
        }
        return true
    },
})

extend('confirmPassword', {
    params: ['target'],
    validate(value, params: Record<string, any>) {
        return value === params.target
    },
    message: i18n.t('customValidationRules.passwordMismatch') as string,
})

extend('licensePlate', {
    validate: (value) => {
        if (value.length > 8) {
            return `License plate number must be 8 characters or less`
        }
        if (value.length < 4) {
            return `License plate number must be at least 4 characters`
        }
        const regex = /^[a-z0-9 ]+$/i
        if (!regex.test(value)) {
            return `License plate number must only contain letters, numbers and spaces`
        }

        return true
    },
})

extend('mortgageRate', {
    validate: (value) => {
        // Remove the '%' sign if it exists and then parse the value
        const rate = parseFloat(value?.replace('%', ''))
        if (isNaN(rate)) {
            return 'Interest rate must be a valid number'
        }
        if (rate < 0) {
            return 'Interest rate must be positive'
        }
        if (rate > 100) {
            return 'Interest rate must be 100% or less'
        }

        return true
    },
})
