import { logger } from '@/utils/logger'
import { isTimeToInteractiveTimerAvailable } from '@/nativeWebInteraction/nativeFeatureAvailability'
import { tellNativeThatWebIsInteractive } from '@/nativeWebInteraction/nativeWebInteraction'
import { AppLifecycleEventName, createLifecycleEvent } from '@/services/api'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import store from '@/store'
import { Route } from 'vue-router'

let timeToInteractiveTimerEnded = false
let startTimestamp: number | undefined

export const startTimeToInteractiveTimer = () => {
    logger.info(`Tracking start of time to interactive timer on Aven Advisor web`)
    startTimestamp = new Date().getTime()
}

export const endTimeToInteractiveTimer = async (route: Route): Promise<void> => {
    logger.info(`Tracking end of time to interactive timer on Aven Advisor web`)
    if (!startTimestamp) {
        logger.fatal(`Tried to end time to interactive timer on Aven Advisor web, but no startTimestamp available`)
        return
    }
    if (timeToInteractiveTimerEnded) {
        logger.info(`Time to interactive timer already ended on Aven Advisor web, not ending again`)
        return
    }
    timeToInteractiveTimerEnded = true
    const timeToInteractiveMillis = new Date().getTime() - startTimestamp

    if (isTimeToInteractiveTimerAvailable()) {
        await tellNativeThatWebIsInteractive()
    }

    let sessionIdentifier
    try {
        logger.log(`Trying to use crypto.randomUUID on Aven Advisor web to construct tti session identifier`)
        sessionIdentifier = crypto.randomUUID()
    } catch (e) {
        logger.log(`Error using crypto.randomUUID on Aven Advisor web, constructing tti session identifier w/ time and random number`)
        sessionIdentifier = `${new Date().getTime()}-${Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)}`
    }

    await createLifecycleEvent(sessionIdentifier, AppLifecycleEventName.avenAdvisorTimeToInteractive, {
        nativeAppVersion: window.nativeAppVersion,
        platformOs: window.platformOs,
        appInstallId: window.installId,
        sessionId: appSessionStorage.getItem(localStorageKey.sessionId),
        timeToInteractiveMillis,
        runtimeType: 'web',
        didUserStartSignedIn: store.getters.isUserSignedIn,
        path: route.fullPath,
    })
}
