<template>
    <div class="loading-indicator">
        <div
            class="spinner-border spinner-border-sm"
            v-if="small"
        />
        <div
            class="spinner-border"
            v-else
        />
        <h5
            class="fw-light title mt-1"
            v-if="title"
            v-html="title"
        />
        <p
            v-if="subTitle"
            class="text-muted text-center"
            v-html="subTitle"
        />
    </div>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
        props: {
            title: String,
            subTitle: String,
            small: {
                type: Boolean,
                default: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/loadingIndicator.scss';
</style>
