import { Features } from 'aven_types'
import { logger } from '@/utils/logger'
type NativeFeatureAvailability = { [T in Features]: boolean | undefined }

const getNativeFeatureAvailabilityJson = (): NativeFeatureAvailability => {
    const nativeFeatureAvailabilityString = window.nativeFeatureAvailability
    logger.log(`Parsing native feature availability from ${nativeFeatureAvailabilityString}`)
    return nativeFeatureAvailabilityString ? JSON.parse(nativeFeatureAvailabilityString) : {}
}

const isFeatureAvailable = (feature: Features): boolean => {
    logger.log(`Determining whether ${feature} is available`)
    const available = !!getNativeFeatureAvailabilityJson()[feature]
    logger.log(`${feature} ${available ? 'is available' : 'is not available'}`)
    return available
}

export const isNativeDocumentDownloadAvailable = (): boolean => {
    return isFeatureAvailable(Features.nativeDocumentDownload)
}

export const isRetrieveContactsAvailable = (): boolean => {
    return isFeatureAvailable(Features.retrieveContacts)
}

export const isExternalWebviewEnabled = (): boolean => {
    return isFeatureAvailable(Features.externalWebview)
}

export const isOpenShareSheetEnabled = (): boolean => {
    return isFeatureAvailable(Features.openShareSheet)
}

export const isCheckPushPermissionEnabled = (): boolean => {
    return isFeatureAvailable(Features.checkPushPermission)
}

export const canReceiveSessionInfoObject = (): boolean => {
    return isFeatureAvailable(Features.canReceiveSessionInfoObject)
}

export const canCloseExternalWebview = (): boolean => {
    return isFeatureAvailable(Features.closeExternalWebview)
}

export const isDeeplinkingAvailable = (): boolean => {
    return isFeatureAvailable(Features.isDeeplinkingAvailable)
}

export const isTimeToInteractiveTimerAvailable = (): boolean => {
    return isFeatureAvailable(Features.isTimeToInteractiveTimerAvailable)
}

export const canLastPushPermissionRequestTimestampBeRecorded = (): boolean => {
    return isFeatureAvailable(Features.canLastPushPermissionRequestTimestampBeRecorded)
}

export const canGetWhetherAppCanAskPushPermissionAgain = (): boolean => {
    return isFeatureAvailable(Features.canGetWhetherAppCanAskPushPermissionAgain)
}

export const canReturnPushPermissionResponse = (): boolean => {
    return isFeatureAvailable(Features.canReturnPushPermissionResponse)
}

export const canTriggerAppsFlyerEventFromWebSignupScreen = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventFromWebSignupScreen)
}

export const isNativeSmsSheetAvailable = (): boolean => {
    return isFeatureAvailable(Features.isNativeSmsSheetAvailable)
}

export const isNativeMailSheetAvailable = (): boolean => {
    return isFeatureAvailable(Features.isNativeMailSheetAvailable)
}

export const isExpoUpdateInfoAvailable = (): boolean => {
    return isFeatureAvailable(Features.isExpoUpdateInfoAvailable)
}

export const isOpenAppSettingsAvailable = (): boolean => {
    return isFeatureAvailable(Features.isOpenAppSettingsAvailable)
}

export const canGetWhetherAppCanAskForContactPermissionAgain = (): boolean => {
    return isFeatureAvailable(Features.canGetWhetherAppCanAskForContactPermissionAgain)
}

export const getIsSetSafeAreaPaddingAvailable = (): boolean => {
    return isFeatureAvailable(Features.isSetSafeAreaPaddingAvailable)
}

export const canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess)
}

export const canTriggerAppsFlyerEventForOTPSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForOTPSubmitSuccess)
}

export const canTriggerAppsFlyerEventForSSNSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForSSNSubmitSuccess)
}

export const canTriggerAppsFlyerEventForPIISubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForPIISubmitSuccess)
}

export const canTriggerAppsFlyerEventForKBASuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForKBASuccess)
}

export const canTriggerAppsFlyerEventForNotificationPermissionGranted = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForNotificationPermissionGranted)
}

export const canTriggerAppsFlyerEventForStatedGoalSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalSubmitSuccess)
}

export const canTriggerAppsFlyerEventForStatedGoalBudgetSpendTrackingSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalBudgetSpendTrackingSubmitSuccess)
}

export const canTriggerAppsFlyerEventForStatedGoalLienReportSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalLienReportSubmitSuccess)
}

export const canTriggerAppsFlyerEventForStatedGoalFindSubscriptionsSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalFindSubscriptionsSubmitSuccess)
}

export const canTriggerAppsFlyerEventForStatedGoalCreditScoreSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalCreditScoreSubmitSuccess)
}

export const canTriggerAppsFlyerEventForStatedGoalOtherSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForStatedGoalOtherSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerPhoneNumberSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerPhoneNumberSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerOTPSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerOTPSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerSSNSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerSSNSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerPIISubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerPIISubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerKBASuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerKBASuccess)
}

export const canTriggerAppsFlyerEventForHomeownerNotificationPermissionGranted = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerNotificationPermissionGranted)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalBudgetSpendTrackingSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalBudgetSpendTrackingSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalLienReportSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalLienReportSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalFindSubscriptionsSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalFindSubscriptionsSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalCreditScoreSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalCreditScoreSubmitSuccess)
}

export const canTriggerAppsFlyerEventForHomeownerStatedGoalOtherSubmitSuccess = (): boolean => {
    return isFeatureAvailable(Features.canTriggerAppsFlyerEventForHomeownerStatedGoalOtherSubmitSuccess)
}
