export enum BottomNavigationPages {
    TODAY = 'TODAY',
    OFFERS = 'OFFERS',
}

const todayIcon = require('@/assets/images/icon-nav-today-unselected.svg')
const todayIconSelected = require('@/assets/images/icon-nav-today-selected.svg')
const offersIcon = require('@/assets/images/icon-nav-offers-unselected.svg')
const offersIconSelected = require('@/assets/images/icon-nav-offers-selected.svg')

export const bottomNavigationPagesConfig: { [key in BottomNavigationPages]: { title: string; selectedIcon: any; unselectedIcon: any; unselectedIconWithBadge: any; dataTestId: string } } = {
    [BottomNavigationPages.TODAY]: { title: 'Today', selectedIcon: todayIconSelected, unselectedIcon: todayIcon, unselectedIconWithBadge: todayIcon, dataTestId: 'bottom-nav-bar-today-button' },
    [BottomNavigationPages.OFFERS]: { title: 'Offers', selectedIcon: offersIconSelected, unselectedIcon: offersIcon, unselectedIconWithBadge: offersIcon, dataTestId: 'bottom-nav-bar-offers-button' },
}
