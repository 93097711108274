import store from '@/store'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { httpClient } from '@/utils/http-client'
import 'url-search-params-polyfill' // Just to be safe
import { openReplay } from './openreplay'
import { initLogRocket } from './logRocket'

export const logUrlProperties = () => {
    if (document.referrer) {
        const parser = new URL(document.referrer)
        window.logEvent('system_aven_init', { referrer: document.referrer, referring_domain: parser.hostname })
    }

    // Parsing invite code from query params for tryaven.com redirects
    // https://tryaven.com/BLAH1234 -> https://aven.com/?referrer=tryaven%2FBLAH1234 where %2F is url encoding of a forward slash '/'
    const query = new URLSearchParams(window.location.search)
    logger.info(`query ${query}; referrer ${document.referrer}; location href ${window.location.href}`)
}

export const submitSessionRecordingUrl = async (sessionId: string | null, sessionRecordingUrl: string) => {
    appSessionStorage.setItem(localStorageKey.sessionRecordingInitialized, 'true')
    appSessionStorage.setItem(localStorageKey.sessionRecordingUrl, sessionRecordingUrl)
    try {
        await httpClient.post('/ana/sessionRecordingUrl', { sessionId, sessionRecordingUrl })
    } catch (error) {
        logger.fatal(`submission failed, sessionRecordingUrl, ${sessionRecordingUrl}`, error)
    }
}

// This currently has no identifiers so this is just a placeholder for now
export const getUserTraits = (): Record<string, any> => {
    return {}
}

export const initializeSessionRecording = () => {
    logger.info(`initializing session recording`)
    const sessionId = store.state.persistedStore.sessionId
    if (sessionId) {
        const userTraits = getUserTraits()
        logger.info(`init session recording with sessionId: ${sessionId} and traits: ${JSON.stringify(userTraits)}`)
        if (process.env.VUE_APP_USE_OPEN_REPLAY === 'true' && process.env.VUE_APP_OPENREPLAY_PROJECT_KEY) {
            logger.info(`init openReplay session with traits: ${JSON.stringify(userTraits)}`)
            openReplay.init(sessionId, userTraits)
        } else if (process.env.VUE_APP_LOGROCKET_ID) {
            logger.info(`init logRocket session with traits: ${JSON.stringify(userTraits)}`)
            initLogRocket(sessionId, userTraits)
        }
    } else {
        logger.fatal(`Could not initialize session recording with no sessionId!`)
    }
}
