"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Features = void 0;
// next time we change aven_score, aven_apps/aven_score/src/nativeFeatureAvailability/nativeFeatureAvailability.ts should be updated to refer this
var Features;
(function (Features) {
    /**
     * Feature in which the native app can download and display documents.
     */
    Features["nativeDocumentDownload"] = "nativeDocumentDownload";
    /**
     * Native app can attempt to retrieve a user's contacts
     */
    Features["retrieveContacts"] = "retrieveContacts";
    /**
     * Native app can open a generic external web view to a specified url
     */
    Features["externalWebview"] = "externalWebview";
    /**
     * Native app can open share sheet
     */
    Features["openShareSheet"] = "openShareSheet";
    /**
     * Native app can check if push permissions are enabled
     */
    Features["checkPushPermission"] = "checkPushPermission";
    /**
     * Whether the native app can receive a session info object containing both
     * session accessToken and session ID
     */
    Features["canReceiveSessionInfoObject"] = "canReceiveSessionInfoObject";
    /**
     * Native app can close the external webview
     */
    Features["closeExternalWebview"] = "closeExternalWebview";
    /**
     * Native app can open deep links
     */
    Features["isDeeplinkingAvailable"] = "isDeeplinkingAvailable";
    /**
     * Whether the native app can keep track of the 'time to interactive' timer
     */
    Features["isTimeToInteractiveTimerAvailable"] = "isTimeToInteractiveTimerAvailable";
    /**
     * Whether the native app can record timestamp for last time it requested push permissions
     */
    Features["canLastPushPermissionRequestTimestampBeRecorded"] = "canLastPushPermissionRequestTimestampBeRecorded";
    /**
     * Whether the web can ask native whether we can ask for push notif permission again
     */
    Features["canGetWhetherAppCanAskPushPermissionAgain"] = "canGetWhetherAppCanAskPushPermissionAgain";
    /**
     * Whether the native app can return a response to a push permission request
     */
    Features["canReturnPushPermissionResponse"] = "canReturnPushPermissionResponse";
    /**
     * Whether the native app can trigger an apps flyer event from the web signup screen
     */
    Features["canTriggerAppsFlyerEventFromWebSignupScreen"] = "canTriggerAppsFlyerEventFromWebSignupScreen";
    /**
     * Whether the native sms sheet is available
     */
    Features["isNativeSmsSheetAvailable"] = "isNativeSmsSheetAvailable";
    /**
     * Whether the native mail sheet is available
     */
    Features["isNativeMailSheetAvailable"] = "isNativeMailSheetAvailable";
    /**
     * Whether Expo update information is available
     */
    Features["isExpoUpdateInfoAvailable"] = "isExpoUpdateInfoAvailable";
    /**
     * Whether Expo can open settings to the aven advisor settings page
     */
    Features["isOpenAppSettingsAvailable"] = "isOpenAppSettingsAvailable";
    /**
     * Whether the native app can return whether the app can ask for contact permission again
     */
    Features["canGetWhetherAppCanAskForContactPermissionAgain"] = "canGetWhetherAppCanAskForContactPermissionAgain";
    /**
     * Whether we can ask native to set set safe area padding on the webview
     */
    Features["isSetSafeAreaPaddingAvailable"] = "isSetSafeAreaPaddingAvailable";
    /**
     * Whether the native app can trigger an apps flyer event for phone number submit success
     */
    Features["canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess"] = "canTriggerAppsFlyerEventForPhoneNumberSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for OTP submit success
     */
    Features["canTriggerAppsFlyerEventForOTPSubmitSuccess"] = "canTriggerAppsFlyerEventForOTPSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for SSN submit success
     */
    Features["canTriggerAppsFlyerEventForSSNSubmitSuccess"] = "canTriggerAppsFlyerEventForSSNSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for PII submit success
     */
    Features["canTriggerAppsFlyerEventForPIISubmitSuccess"] = "canTriggerAppsFlyerEventForPIISubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for KBA success
     */
    Features["canTriggerAppsFlyerEventForKBASuccess"] = "canTriggerAppsFlyerEventForKBASuccess";
    /**
     * Whether the native app can trigger an apps flyer event for notification permission granted
     */
    Features["canTriggerAppsFlyerEventForNotificationPermissionGranted"] = "canTriggerAppsFlyerEventForNotificationPermissionGranted";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal budget spend tracking submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalBudgetSpendTrackingSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalBudgetSpendTrackingSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal lien report submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalLienReportSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalLienReportSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal find subscriptions submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalFindSubscriptionsSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalFindSubscriptionsSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal credit score submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalCreditScoreSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalCreditScoreSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for stated goal other submit success
     */
    Features["canTriggerAppsFlyerEventForStatedGoalOtherSubmitSuccess"] = "canTriggerAppsFlyerEventForStatedGoalOtherSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner phone number submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerPhoneNumberSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerPhoneNumberSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner OTP submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerOTPSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerOTPSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner SSN submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerSSNSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerSSNSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner PII submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerPIISubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerPIISubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner KBA success
     */
    Features["canTriggerAppsFlyerEventForHomeownerKBASuccess"] = "canTriggerAppsFlyerEventForHomeownerKBASuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner notification permission granted
     */
    Features["canTriggerAppsFlyerEventForHomeownerNotificationPermissionGranted"] = "canTriggerAppsFlyerEventForHomeownerNotificationPermissionGranted";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal budget spend tracking submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalBudgetSpendTrackingSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalBudgetSpendTrackingSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal lien report submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalLienReportSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalLienReportSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal find subscriptions submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalFindSubscriptionsSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalFindSubscriptionsSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal credit score submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalCreditScoreSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalCreditScoreSubmitSuccess";
    /**
     * Whether the native app can trigger an apps flyer event for homeowner stated goal other submit success
     */
    Features["canTriggerAppsFlyerEventForHomeownerStatedGoalOtherSubmitSuccess"] = "canTriggerAppsFlyerEventForHomeownerStatedGoalOtherSubmitSuccess";
})(Features = exports.Features || (exports.Features = {}));
