import Vue from 'vue'
import VueRouter from 'vue-router'
import { logger, inspect } from '@/utils/logger'
import { currentContextForLogging } from '@/main'
import VueGtag from 'vue-gtag'
import { onboardingRoutes } from '@/routes/onboardingRoutes'
import store from '@/store'
import { signInRoutes } from '@/routes/signInRoutes'
import { appRoutePaths, appRoutes } from '@/routes/appRoutes'
import { sharedRoutePaths, sharedRoutes } from '@/routes/sharedRoutes'
import { passwordResetRoutes } from '@/routes/passwordResetRoutes'
import { supportRoutes } from '@/routes/supportRoutes'
import { saveCurrentURLPath } from '@/nativeWebInteraction/nativeWebInteraction'

Vue.use(VueRouter)

const routes = [...sharedRoutes, ...onboardingRoutes, ...signInRoutes, ...passwordResetRoutes, ...appRoutes, ...supportRoutes]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
})

if (['production', 'staging', 'uat', 'test'].includes(process.env.VUE_APP_NODE_ENV as string)) {
    Vue.use(
        VueGtag,
        {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG },
        },
        router
    )
}

router.onError((error) => {
    logger.info(`router error: ${inspect(error)}`)
    // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
    if (/Loading.*chunk.*failed./i.test(error.message)) {
        logger.info('Reloading page to fix stale chunk error')
        return window.location.reload()
    }

    throw error
})

router.afterEach((to) => {
    try {
        for (const member in currentContextForLogging) {
            delete currentContextForLogging[member]
        }
        saveCurrentURLPath(to.path)
    } catch (e) {
        logger.fatal(`error clearing current context for logging: ${e}`)
    }
})

// Documentation: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
router.beforeEach((to, from, next) => {
    logger.info(`Aven Advisor trying to route from: ${from.path} to: ${to.path}`)
    window.previousPath = from.path

    logger.info(`Aven Advisor trying to route from: ${from.path} to: ${to.path}`)

    // remove any modals if needed
    document.body.classList.remove('modal-open')
    document.getElementById('modal-backdrop')?.remove()

    const hasAuthTokens = !!store.state.persistedStore.jwtTokens
    const pathRequiresAuth = to.meta?.requiresAuth
    if (pathRequiresAuth && !hasAuthTokens) {
        logger.log(`Aven Advisor path ${to.path} requires auth, but none found. Routing to ${sharedRoutePaths.ROOT}`)
        return next({ path: sharedRoutePaths.ROOT, replace: true })
    } else if (!pathRequiresAuth && hasAuthTokens) {
        logger.log(`Aven Advisor path ${to.path} does not requires auth, but we have auth tokens`)
        logger.log(`router.beforeEach: Routing to ${appRoutePaths.TODAY}`)
        return next({ path: appRoutePaths.TODAY, replace: true })
    }

    logger.log(`Aven Advisor to path (${to.path}) and presence of auth tokens (${hasAuthTokens}) matches. Continuing routing to requested path.`)
    next()
})

export default router
